html {
  //font-size: 16px;
  font-size: 17px;
  @include media-breakpoint-up(lg) {
    //font-size: 18px;
    font-size: 20px;
  }
}

body {
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  min-height: 100vh;
  background: $bg-color;
  font-weight: $weight-normal;
  color: $font-color; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-size: 1.2rem;
  // @include media-breakpoint-up(lg) {
  //   font-size: 22px;
  // }
}