.bannerPanel > * {
  display: none;

  &:nth-child(1) {
    display: block;
  }
}


.bannerPanel .slick-slider .slick-track {
  //background-color: #414042;
  background-color: white;
}
.slick-next { right: 20px; z-index: 1;}
.slick-prev { left: 20px; z-index: 1; }