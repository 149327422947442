.productView {
  .tab {
    padding: 0.3rem 0.5rem;
    //background: #ddd;
    color: #999;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;

    &:before {
      transition: all 0.4s ease-in-out;
      content: '';
      background: #ddd;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: skewX(-12deg) translateX(-5px);
      transform-origin: right top;
      position: absolute;
      z-index: -1;
    }

    &:hover, &:focus, &.selected {
      color: white;
      &:before {
        background: $primary-color;
      }
    }
  
  }
}

.productView__tabs {
  margin: 1rem -0.4rem;
  display: flex;
  .tab {
    flex: 1 1 auto;
    min-width: 15%;
    margin: 0 0.4rem;
  }
}

.productView {
  .tabContent {
    // position: absolute;
    // opacity: 0;
    // z-index: -1;
    // max-height: 0;
    // transition: opacity 0.3s;
    display:none;
    &.selected {
      display: block;
      // opacity: 1;
      // z-index: 1;
      // max-height: none;
    }
  }

}


.productView__tabContents {
  position: relative;

  ul li {
    margin-bottom: 0.3rem;
  }

  h4 {
    font-size: 1.8rem;
    font-family: DIN Next LT Pro,serif;
    font-weight: 400;
  }

  .video {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

}

