b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    color: $link-hover-color;
    //text-decoration: none;
  }
}

h1,
h2,
h3 {
  font-family: $family-base;
  //color: $header-color;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h4 {
  //font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1px;
}

h5 {
  //font-weight: 400;
  line-height: 1.2;
}


// size
h1 {
  //old
  // font-size: 2.5rem;
  // line-height: 1;
  // font-weight: $weight-light;
  
  //new
  font-size: 2.5rem;
  line-height: 1;
  margin: 0.3em 0;
  font-family: $family-header;
}

h2 {
  font-size: 2.2rem;
  line-height: 1;
  margin: 0.3em 0;
  font-family: $family-header;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.3rem;
  line-height: 1.4;
}

h5 {
  font-size: 0.8rem;
}

h1, h2, h3, h4, h5{
  font-weight: $weight-normal;
}

// @include media-breakpoint-up(sm){
//   h1 {
//     font-size: 3.4rem;
//   }
// }


@include media-breakpoint-up(lg) {

  h1 {
    //font-size: 5rem;
    font-size: 3rem;
  }

  h2 {
    //font-size: 4rem;
    font-size: 2.4rem;
  }

  h3 {
    //font-size: 2.6rem;
    font-size: 1.8rem;
  }

  h4 {
    //font-size: 1.4rem;
    font-size: 1.3rem;
  }

  h5 {
    font-size: 0.8rem;
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}