$top-bar-z-index: 1000 !default;

.topNav {
  transition: all 0.2s ease-in-out;
  position: fixed;
  //background: #fff;
  background-color: black;
  //background-color: rgba($color: #fff, $alpha: 0.8);
  z-index: $top-bar-z-index;
  height: 66px;
  top: 0;
  width: 100%;
  font-size: 0.75rem;

  //for scroll
  .topNav__logo{
    img {
      transition: all 0.2s ease-in-out;
      width: 230px;
      @include media-breakpoint-up(lg) {
        width: 360px;
      }
    } 
  }

  &.scrolled{
    //background-color: rgba($color: #fff, $alpha: 0.8);
    height: map-get($top-bar-height, mobile);
    @include media-breakpoint-up(lg) {
      height: map-get($top-bar-height, desktop);
    }
    .topNav__logo{
      img {
        width: 150px;
        @include media-breakpoint-up(lg) {
          width: 190px;
        }
      }
    }
    .hamburger, a.topNav__back{
      top: 0px;
    }
    .navBar{
      top: map-get($map: $top-bar-height, $key: mobile);
      @include media-breakpoint-up(lg){
        top: map-get($map: $top-bar-height, $key: desktop);
      }
    }
  }
  //for scroll

  @include media-breakpoint-up(lg) {
    //height: map-get($top-bar-height, desktop);
    //height: 56px;
    height: 100px;
  }
}

a.topNav__back {
  position: absolute;
  left: 1rem;
  top: 10px;
  line-height: map-get($top-bar-height, mobile);
  //color: $font-color;
  color: white;
  &:hover {
    //color: $font-color;
    color: white;
    text-decoration: none;
  }
  @include media-breakpoint-down(xs){
    font-size: 0.6rem;
  }
  @include media-breakpoint-up(lg) {
    line-height: map-get($top-bar-height, desktop);
    top: 22px;
  }
}

.topNav__siteSwitch {
  position: absolute;
  right: 1.5rem;
  line-height: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    right: 2rem;
    line-height: map-get($top-bar-height, desktop);
  }
}

// .topNav__men {
//   color: white;
//   letter-spacing: 2px;
//   &:hover {
//     color: white;
//     text-decoration: none;
//   }
// }

// .topNav__women {
//   color: $primary-color;
//   font-weight: bold;
//   letter-spacing: 2px;
// }

.topNav__sep {
  display: inline-block;
  padding: 0 1rem;
  color: white;
}