.navBar__scrollArea {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 170px);
  @include media-breakpoint-up(lg) {
    max-height: calc(100vh - 190px);

    // .scrolled &, .subpage & {
    //   max-height: calc(100vh - 150px);
    // }
  }
}

.navBAr__bottomArea{
  text-align: center;
  padding: 10px;
  img{
    width: 50%;
  }
}