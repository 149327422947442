@mixin tile--titleOnly($breakpoints:(xs:1, sm:2, md:3, lg:4), $margin: 20px, $ratio: 56.25%) {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display:flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .tile {
    padding: $margin/2;

    @each $bp, $number in $breakpoints {
      @include media-breakpoint-up(#{$bp}) {
        flex: 0 0 100%/$number;
        max-width: 100%/$number;
      }
    }
  }

  .tile__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:100%;
    cursor: pointer;
    position: relative;
  }

  .tile__bg {
    @include bg-img();
    @include img-ratio($ratio);
  }
  
}
