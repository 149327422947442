.pageTiles {
  //@include tile--titleDesc($ratio: 40%);
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;

    .pageTile_details:before {
      background: $primary-color;
    }
    .pageTile__title,
    .pageTile_desc {
      color: white;
    }

    .pageTile_details{
      background-color: rgba($color: $primary-color, $alpha: 1);
    }

  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 2rem;
  text-transform: uppercase;
  //font-weight: bold;
  line-height: 1;
  //margin: 0.5rem 0 0px;
  margin: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $font-color;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 1rem;
  color: $font-color;
  min-height: 70px;
}

.pageTile_details {
  overflow: hidden;
  flex-grow: 1;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
  bottom: 0px;
  background-color: rgba($color: $primary-color, $alpha: 0.5);
  flex: 1 1 300px;
  transition: all 0.2s ease-in-out;
}


.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile__detail__container{
  position: relative;
  top: 44%;
  transform: translateY(-50%);
}