.bottomMenu {
  //color: white;
  position: fixed;
  z-index: 100;
  //bottom: 10px;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 0.8);
  padding-top: 0.2rem;
  //background-color: rgba(#fff, 0.9);
  //transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    //bottom: 20px;
    padding-bottom: 20px;
  }
  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      max-width: 560px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
}


// .bottomMenu__li {
//   transition: all 0.5s ease-out;
//   &:hover, &:focus, &.selected {
//     background-color: rgba(#000, 0.1);
//   }
// }

.bottomMenu__link {

  display: inline-block;
  color: $font-color;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  //opacity: 0.7;
  //padding-bottom: 10px;
  font-family: $family-base;
  * {
    position: relative;
    z-index: 100;
  }

  .icon {
    // width: 32px;
    // height: 32px;
    width: 20px;
    height: 20px;
    @include bg-img();
    margin: auto;
    transition: all 0.3s ease-out;
  }

  div {
    font-size: 0.6rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
  }
  &:hover, &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: $font-color;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    //padding: 15px 5px;
    padding: 6px 5px;
    .icon {
      // width: 40px;
      // height: 40px;
      width: 25px;
      height: 25px;
    }

    div {
      //font-size: 0.9rem;
      font-size: 0.7rem;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
  }
}


.bottomMenu__link--0 .icon {
  background-image: url('/assets/navicons/products.png');
  //opacity: 0.5;
}
.bottomMenu__link--0:hover .icon
//.bottomMenu__link--0.selected .icon,
//.bottomMenu__li.selected .bottomMenu__link--0 .icon
{
  background-image: url('/assets/navicons/contact.png');
  //opacity: 1;
  //transform: scale(1.2);
}

.bottomMenu__link--1 .icon {
  background-image: url('/assets/navicons/products.png');
  //opacity: 0.5;
}
.bottomMenu__link--1:hover .icon
//.bottomMenu__link--1.selected .icon,
//.bottomMenu__li.selected .bottomMenu__link--1 .icon
{
  background-image: url('/assets/navicons/contact.png');
  //opacity: 1;
  //transform: scale(1.2);
}

.bottomMenu__link--2 .icon {
  background-image: url('/assets/navicons/products.png');
  //opacity: 0.5;
}
.bottomMenu__link--2:hover .icon
//.bottomMenu__link--2.selected .icon,
//.bottomMenu__li.selected .bottomMenu__link--2 .icon
{
  background-image: url('/assets/navicons/contact.png');
  //opacity: 1;
  //transform: scale(1.2);
}

.bottomMenu__link--3 .icon {
  background-image: url('/assets/navicons/products.png');
  //opacity: 0.5;
}
.bottomMenu__link--3:hover .icon
//.bottomMenu__link--3.selected .icon,
//.bottomMenu__li.selected .bottomMenu__link--3 .icon
{
  background-image: url('/assets/navicons/contact.png');
  //opacity: 1;
  //transform: scale(1.2);
}

.bottomMenu__link--4 .icon {
  background-image: url('/assets/navicons/products.png');
  //opacity: 0.5;
}
.bottomMenu__link--4:hover .icon
//.bottomMenu__link--4.selected .icon,
//.bottomMenu__li.selected .bottomMenu__link--4 .icon
{
  background-image: url('/assets/navicons/contact.png');
  //opacity: 1;
  //transform: scale(1.2);
}
//https://tympanus.net/Development/IconHoverEffects/#set-8