// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova", sans-serif;
$family-header: $family-base;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-normal: $weight-light;

// colors - sister colours from bootrap
// $color-blue:    #007bff;
// $color-red:     #fe3a43;
// $color-orange:  #fd7e14;
// $color-green:   #28a745;

// more colors
//$color-teal: #30b0c5;
$color-gold: #c78346;
$color-dark-gray: #252525;
$color-light-gray: #333333;
$color-black: black;
//$color-red: #ec452f;
$color-blue: #0071bc;
$color-dark-blue: #1b1664;
$color-light-blue: #3399cc;

// site colors
$primary-color: $color-blue;
$font-color: $color-light-gray;
$font-color-white: #fff;
$header-color: $font-color;
$bg-color:#fff;

$link-color: $primary-color;
$link-hover-color: $color-light-blue;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
$top-bar-height: (mobile: 44px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;