.cms_editing_item:focus {
  outline: none;
}

.cms_editing_item {
  border: orange dashed 2px;
  min-height: 15px;
  min-width: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cms_editing_text:focus {
  outline: none;
}

.cms_editing_text {
  -webkit-box-shadow: inset 0 0 4px orange;
  /* border: orange dashed 2px;*/
}

/* disable page hiding */

.hideview {
  display: block;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-cms-html] {
  min-height: 1.2em;
}

img[data-embed-jaba] {
  display: block;
  clear: both;
  margin: 5px;
}

.partial-editing-top-padding, .partial-editing-bottom-padding {
  padding: 20px;
  color: white;
  text-align: center;
  background: #222;
}
.partial-editing-top-padding {
  margin-top:100px;
}
.partial-editing-bottom-padding {
  margin-bottom:100px;
}
