.htmlPanel {
  max-width: 960px;
  margin: auto;
}

div.imagePanel{
  position: relative;
}

div.imagePanel .htmlPanel{
  position: absolute;
  //width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img{
    max-width: 100%;
  }
}