@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-day-picker/lib/style.css';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
@import './layout/hamburger';
@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';

@import './modules/youtube';

// components
@import '../components';

@import './site/index';


.container{
    @include media-breakpoint-up(xl){
        max-width: 1140px;
    }
}

a.topNav__back svg{
    margin-top: -3px;
    font-size: 0.75rem;
}


div.appInfo{
    text-align: center;
}

// .testimonialPanel .slick-list{
//     display: flex !important;
// }

// .slick-initialized .slick-slide > div{
//     height: 100%;
// }